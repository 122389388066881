import { _isCrossSellingApplicable, _isCrossSellingAvailable } from './search_services';

export const cartCalculations = (state: any) => {
  const payload: any = state.services;
  const { cross_selling } = state;
  /**
   * Control Variables
   * @total
   * @total_without_discount
   * @total_discount
   * @discountRules
   */
  let total: any = 0;
  let total_without_discount: any = 0;
  let total_discount: any = 0;
  let discountRules: any = null;
  let deliveryRules: any = null;
  let delivery: any = null;
  let total_discount_by_day: any = 0;
  let total_discount_by_cross_selling: any = 0;

  /**
   * DISCOUNT BY DAY CALCS
   */

  payload.map((service: any) => {
    /**
     * Set total price to a variable
     * @price_total (service.service.price_total)
     */

    if (service.service.quantity) {
      service.service.price_total = service.service.price * service.service.quantity;
      total_without_discount += service.service.price_total;
    } else {
      service.service.price_total = service.service.price;
      total_without_discount += service.service.price_total;
    }

    /**
     *  Checks which discount is better (day or cross selling)
     *  @cumulative_day_discount
     *
     */

    const isDiscountByDayAvailable: boolean = service.schedule && parseInt(service.schedule?.hour?.discount) > 0 ? true : false;

    if (isDiscountByDayAvailable) {
      [service, total_discount] = _handleDiscountByDay(service, total_discount);
      total_discount_by_day = total_discount;

      discountRules = {
        discount: service.schedule?.hour?.discount,
        accumulate: undefined,
        type: 'by_day',
        description: 'Descuento promocional por hora',
      };
    } else {
      service.service.price_discount = 0;
    }

    /**
     * @total
     * Add the service price to total with discount or not
     */

    if (service.service.price_discount) {
      total = total + service.service.price_discount;
    } else {
      total = total + service.service.price_total;
    }

    /**
     * Set related products prices
     */

    if (service.related_products) {
      service.related_products.map((rp: any) => {
        rp.price = rp.price || rp.pricing || rp.value || rp.precio;
        if (rp.quantity) {
          rp.price_total = rp.price * rp.quantity;
          total_without_discount += rp.price_total;
        } else {
          rp.price_total = rp.price;
          total_without_discount += rp.price_total;
        }
        total = total + parseInt(rp.price_total);
        rp.price_discount = 0;
        return rp;
      });
    }

    return service;
  });

  /**
   * CROSS SELLING CALCS
   */
  total_discount_by_day = parseInt(total_discount_by_day);

  let isDiscountByCrossSellingAvailable: boolean = _isCrossSellingAvailable(state);
  let isDiscountByCrossSellingApplicable: boolean = _isCrossSellingApplicable(state);

  if (!cross_selling?.is_available) isDiscountByCrossSellingAvailable = false;

  if (isDiscountByCrossSellingAvailable && isDiscountByCrossSellingApplicable) {
    total_discount_by_cross_selling = (total_without_discount * parseInt(cross_selling.percentage)) / 100;
    total_discount_by_cross_selling = parseInt(total_discount_by_cross_selling);
  }

  if (total_discount_by_cross_selling > total_discount_by_day) {
    discountRules = {
      discount: parseInt(cross_selling.percentage),
      accumulate: true,
      type: 'cross_selling',
      description: cross_selling?.description,
    };

    const { discount } = discountRules;
    total = 0;
    total_discount = 0;

    payload.map((service: any) => {
      let price_discount: any = 0;
      if (service.service.quantity) {
        price_discount = (service.service.price - (service.service.price * discount) / 100) * service.service.quantity;
        total_discount += ((service.service.price * discount) / 100) * service.service.quantity;
      } else {
        price_discount = service.service.price - (service.service.price * discount) / 100;
        total_discount += (service.service.price * discount) / 100;
      }

      service.service.price_discount = parseInt(price_discount);
      service.service.amount_discount = service.service.price_total - service.service.price_discount;

      total += price_discount;

      if (service.related_products) {
        service.related_products.map((rp: any) => {
          rp.price = rp.price || rp.pricing || rp.value || rp.precio;

          if (rp.quantity) {
            rp.price_total = rp.price * rp.quantity;
          } else {
            rp.price_total = rp.price;
          }
          rp.amount_discount = (rp.price_total * discount) / 100;

          rp.price_discount = rp.price_total - rp.amount_discount;

          total += parseInt(rp.price_discount);

          total_discount += rp.amount_discount;
          return rp;
        });
      }

      return service;
    });
  }

  const keroseneServices = payload.filter((service: any) => service.type === 'kerosene');

  if (keroseneServices.length === 0) {
    deliveryRules = null;
  }

  if (keroseneServices.length === 1) {
    if (keroseneServices[0].initial_shipping_price) keroseneServices[0].shipping_price = JSON.parse(JSON.stringify(keroseneServices[0].initial_shipping_price));
  }

  // Validate if exist more than one kerosene service
  if (keroseneServices.length > 1) {
    deliveryRules = {
      description: 'Descuento en despacho por multiples servicios Kerosene',
      type: 'delivery',
      discount: 0,
    };
    keroseneServices.sort((a: any, b: any) => (a.shipping_price < b.shipping_price ? -1 : 1));

    keroseneServices.map((service: any, index: number) => {
      if (service.initial_shipping_price) service.shipping_price = JSON.parse(JSON.stringify(service.initial_shipping_price));
      service.initial_shipping_price = JSON.parse(JSON.stringify(service.shipping_price));
      if (index < keroseneServices.length - 1) {
        service.is_shipping_price_discounted = true;
        total_discount += service.shipping_price;
        deliveryRules.discount += service.shipping_price;

      }
      return service;
    });

    if (deliveryRules.discount === 0) {
      deliveryRules = null;
    }
  }


  if (keroseneServices.length > 1) {

    delivery = payload.reduce((acc: any, current: any) => {
      acc += current.shipping_price;
      return current.shipping_price;
    }, 0);

  }else{
    
    delivery = payload.reduce((acc: any, current: any) => {
      acc += current.shipping_price;
      return acc;
    }, 0);

  }




  total_discount = parseInt(total_discount);

  const response: any = {
    services: payload,
    total: total_without_discount - total_discount,
    delivery,
    total_without_discount: parseInt(total_without_discount),
    discount: total_discount,
    discountRules,
    deliveryRules,
  };

  response.total = parseInt(response.total);

  return response;
};

const _handleDiscountByDay = (service: any, total_discount: any) => {
  const discount: any = parseInt(service.schedule.hour.discount) > 0 ? parseInt(service.schedule.hour.discount) : false;
  /**
   * Apply discount by hour
   */
  if (discount !== false) {
    let price_discount: any = 0;
    if (service.service.quantity) {
      price_discount = (service.service.price - (service.service.price * discount) / 100) * service.service.quantity;
      total_discount += ((service.service.price * discount) / 100) * service.service.quantity;
    } else {
      price_discount = service.service.price - (service.service.price * discount) / 100;
      total_discount += (service.service.price * discount) / 100;
    }

    service.service.price_discount = parseInt(price_discount);
    service.service.amount_discount = service.service.price_total - service.service.price_discount;
  } else {
    service.service.price_discount = 0;
  }

  return [service, total_discount];
};
