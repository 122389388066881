/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import Images from '../../assets/image';
import Image from '../Image';
import QuantitySelect from '../QuantitySelect';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { close_cart } from '../../redux/actions/shopping_cart';

const CheckoutServiceItemMobile = (props: any) => {
  const { service, _handlePlusService, _handleMinusService, _handleDeleteService } = props;
  const [state, setState] = useState({ delivery_date: '' });
  const dispatch: any = useDispatch()

  useEffect(() => {
    if (service.type === 'kerosene' || service.type === 'mini-kerosene') {
      const today: any = moment();
      const todayLimit: any = moment();
      todayLimit.set({ hour: 21, minute: 0, second: 0, millisecond: 0 });
      if (today.isAfter(todayLimit)) {
        today.add(2, 'days');
      } else {
        today.add(1, 'days');
      }

      setState({ ...state, delivery_date: `Fecha de entrega: ${today.format('DD/MM/YYYY')}` });
    }

    if (service.type !== 'kerosene' && service.type !== 'mini-kerosene') {
      if (service.schedule?.hour) {
        setState({ ...state, delivery_date: `Fecha agendamiento: ${moment(service.schedule?.hour?._moment).format('DD/MM/YYYY HH:mm')}` });
      }
    }
  }, []);

  const _handleEditService = (service: any) => {
    dispatch(close_cart())
    if (service.type === 'subscription') window.location.href = `/services/${service.slug}`;
    if (service.type !== 'subscription') window.location.href = `/services/${service.slug}?id=${service.id}`;
  }

  return (
    <>
      <div className="col-3">
        <Image
          className="w-100 rounded"
          image={
            service.service?.info?.gallery?.url
              ? {
                default: {
                  src: service.service?.info?.gallery?.url,
                },
              }
              : Images.NotFound
          }
        />
      </div>
      <div className="col-9 size-08 color-black bold m-0 p-0">
        <div className="row m-0 p-0">
          <div className="col-9">
            <div
              dangerouslySetInnerHTML={{
                __html: service.service._html || service.service.name,
              }}
            />
            
            {service.car && service.car?.brand ? (<div className='size-08 color-grey bold-300'>{`Patente: ${service.car?.plate?.toUpperCase()}`}</div>) : null}
            
          </div>
          <div className="col-3 color-black size-11 d-flex align-items-center">
            <div>${service.service.price_total?.toLocaleString('es-CL')}</div>
          </div>
          <div className="col-12 bold-300 mt-1">
            <div className="color-blue-base">{state.delivery_date}</div>
          </div>
        </div>
      </div>

      <div className="col-12 d-flex justify-content-between mt-3">
        <div>
          <button className="btn color-blue-base size-08" onClick={() => _handleDeleteService(service)}>
            <u>Eliminar</u>
          </button>
          {service.type === 'subscription' || !service.service.quantity ? (
            <button className="btn color-blue-base size-08" onClick={() => _handleEditService(service)}>
              <u>Editar</u>
            </button>
          ) : null}

        </div>
        {service.type !== 'subscription' && service.service.quantity > 0 && <QuantitySelect quantity={service.service.quantity} onAdd={() => _handlePlusService(service)} onRemove={() => _handleMinusService(service)} />}
      </div>
    </>
  );
};

export default CheckoutServiceItemMobile;
