import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import Images from '../../assets/image';
import ErrorValidation from '../../components/ErrorValidation';
import Image from '../../components/Image';
import validate from '../../helpers/validate';
import { LOAD_OFF, LOAD_ON } from '../../redux/actions/loader';
import CarService from '../../services/cars.service';
import sessionService from '../../services/session.service';
import { CarSchema, CarWithTypeSchema, PlateSchema } from '../../validations/car.validations';
import SelectCarData from '../ServiceLavamax/SelectCarData';
import SelectManualCarData from '../ServiceLavamax/SelectManualCarData';

const INITIAL_STATE = {
  inputs: {
    plate: '',
    model: '',
    brand: '',
    year: '',
    type: '',
  },
  models: [],
  brands: [],
  validations: {},
  formState: {
    plate: false,
    brand: false,
    model: false,
    searchPlate: true,
    manualData: false,
  },
  inputState: {
    plate: false,
    model: false,
    brand: false,
    year: false,
  },
  locale: {
    noResultsText: 'No se encontraron resultados',
    searchPlaceholder: 'Buscar',
  },
};

/* eslint-disable react-hooks/exhaustive-deps */
const CarCreate = (props: any) => {
  const [state, setState] = useState<any>(INITIAL_STATE);
  const history = useHistory();
  const dispatch = useDispatch();
  const Car = new CarService();

  const _handleBrand = async (e: any) => {
    dispatch(LOAD_ON());
    try {
      if (e === null) {
        setState({ ...state, models: [] });
        return;
      }
      const response = await Car.getModels(e);
      const { data } = response;
      const models = data.map((brand: any) => {
        return {
          value: brand.IdMarca,
          label: brand.NombreModelo,
        };
      });
      delete state.validations.brand;
      setState({ ...state, models, inputs: { ...state.inputs, brand: e } });
    } catch (err) {
      console.log(err);
    }
    dispatch(LOAD_OFF());
  };

  const _handleChangeInput = ({ target }: any) => {
    setState({ ...state, inputs: { ...state.inputs, [target.name]: target.value } });
  };

  const _handleManualSection = (e: any) => {
    e.preventDefault();
    setState({ ...state, formState: { ...state.formState, manualData: true }, inputs: { ...state.inputs, brand: '', model: '', year: '' } });
  };

  const _handleAddCar = async () => {
    const validations = state.formState.manualData ? await validate(CarWithTypeSchema, state.inputs) : await validate(CarSchema, state.inputs);
    if (validations) {
      setState({ ...state, validations });
    } else {
      dispatch(LOAD_ON());
      let payload: any = {};
      if (state.formState.manualData) {
        payload = {
          plate: state.inputs.plate,
          year: Number(state.inputs.year),
          main: false,
          type: 'null',
          info: {
            model: {
              id: 1,
              label: state.inputs.model,
            },
            brand: {
              id: 1,
              label: state.inputs.brand,
            },
            size: state.inputs.type,
          },
        };
      } else {
        const model = state.models.find((item: any) => parseInt(item.value) === parseInt(state.inputs.model));
        const brand = state.brands.find((item: any) => parseInt(item.value) === parseInt(state.inputs.brand));
        payload = {
          plate: state.inputs.plate,
          year: state.inputs.year,
          main: false,
          type: 'null',
          info: {
            model: {
              id: model.value,
              label: model.label,
            },
            brand: {
              id: brand.value,
              label: brand.label,
            },
            size: state.IdMedidaVehiculo ? state.IdMedidaVehiculo : 2,
          },
        };
      }

      try {
        await sessionService.createVehicle(payload);
        history.push('/profile/my-cars?success=ok');
      } catch (e) {
        console.log('LOGGER', e);
        toast.error('No fue posible agregar el vehículo');
      }
      dispatch(LOAD_OFF());
    }
  };

  const _handlePlate = async () => {
    const validations = await validate(PlateSchema, state.inputs);
    if (validations) {
      setState({ ...state, validations });
      return;
    }
    dispatch(LOAD_ON());
    try {
      const response = await Car.getDataByPlate({ patente: state.inputs.plate });
      const { data } = response;
      if (!data) {
        const brands = await _getBrands();
        setState({
          ...state,
          brands,
          formState: { ...state.formState, plate: true, searchPlate: false },
          inputState: { ...state.inputState, plate: true },
          inputs: { ...state.inputs, model: '', brand: '', year: '' },
        });
      } else {
        const brands = [
          {
            value: data.IdMarca || 1,
            label: data.Marca || 'Marca no informada',
          },
        ];
        const models = [
          {
            value: data.IdModelo || 1,
            label: data.Modelo || 'Modelo no informado',
          },
        ];

        setState({
          ...state,
          brands,
          models,
          inputs: { ...state.inputs, model: models[0].value, brand: brands[0].value, year: data.Anho },
          formState: { ...state.formState, plate: true, searchPlate: false },
          inputState: {
            plate: true,
            model: true,
            brand: true,
            year: true,
          },
          IdMedidaVehiculo: data.IdMedidaVehiculo,
          validations: {},
        });
      }
    } catch (err) {
      console.log(err);
    }
    dispatch(LOAD_OFF());
  };

  const _getBrands = async () => {
    try {
      const response = await Car.listBrands();
      const { data } = response;
      const brands = data.map((brand: any) => {
        return {
          value: brand.IdMarca,
          label: brand.NombreMarca,
        };
      });
      return brands;
    } catch (err) {
      console.log(err);
    }
  };

  const _clearInputs = () => {
    setState(INITIAL_STATE);
  };

  return (
    <div>
      <div className="row px-3 mb-3 mt-5">
        <div className="col-12 color-black position-relative">
          <div className="bold ps-2 mb-2">Ingresa la patente</div>
          <input
            className="input-default rounded-10 py-3 px-4"
            onChange={_handleChangeInput}
            value={state.inputs.plate}
            name="plate"
            style={{ textTransform: 'uppercase' }}
            disabled={state.inputState.plate}
            maxLength={6}
          />
          {!state.formState.searchPlate && <Image image={Images.CloseBlue} className="position-absolute" style={{ right: '30px', top: '50px' }} onClick={_clearInputs} />}
          <ErrorValidation className="ps-4 size-08" validations={state.validations} name="plate" />
        </div>
      </div>

      {state.formState.searchPlate && (
        <div className="d-flex justify-content-center align-items-center m-4 color-white">
          <button className="btn-default p-2 w-50 size-11 rounded-30 color-white" onClick={_handlePlate}>
            Confirmar
          </button>
        </div>
      )}

      {state.formState.plate && !state.formState.manualData && (
        <SelectCarData state={state} _handleBrand={_handleBrand} _handleChangeInput={_handleChangeInput} _handleAddCar={_handleAddCar} _handleManualSection={_handleManualSection} />
      )}

      {state.formState.plate && state.formState.manualData && (
        <SelectManualCarData state={state} _handleBrand={_handleBrand} _handleChangeInput={_handleChangeInput} _handleAddCar={_handleAddCar} _handleManualSection={_handleManualSection} />
      )}
    </div>
  );
};
export default CarCreate;
